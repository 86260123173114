export const translations = {
    en: {
      language: "Language",
      SelectSystem:"Select System",
      DellARAssistantVideoPortal:"Dell AR Assistant Video Portal",
      Pleaseentertheauthenticationcodeyouhavereceived:"Please enter the authentication code you have received",
      PleaseentercorrectPassword:"Please enter correct Password!",
      Confirm:"Confirm",
      Resend:"Resend",
      Invalidurlpleasetrywithsomevalidurl:"Invalid url, please try with some valid url",
      series:"series",
      Partreplacement:"Part replacement",
      videos:"videos",
      Selectaparttopreviewthepartreplacementprocedure:"Select a part to preview the part replacement procedure",
      Remove:"Remove",
      Install:"Install",
      Step:"Step",
      sharefeedback:"Share feedback",
      appreciate:"We appreciate your valuable feedback, write to us at",
    },
    ccc: {
        language: "语言",
        SelectSystem:"选择系统",
        DellARAssistantVideoPortal:"戴尔AR助手视频门户",
        Pleaseentertheauthenticationcodeyouhavereceived:"请输入您收到的身份验证代码",
        PleaseentercorrectPassword:"请输入正确的密码！",
        Confirm:"确认",
        Resend:"重新发送",
        Invalidurlpleasetrywithsomevalidurl:"无效的网址，请尝试使用一些有效的网址",
        series:"系列",
        Partreplacement:"零件更换",
        videos:"视频",
        Selectaparttopreviewthepartreplacementprocedure:"选择要预览零件更换过程的零件",
        Remove:"拆卸",
        Install:"安装",
        Step:"步骤",
        sharefeedback:"分享反饋",
      appreciate:"感謝您提供寶貴的反饋意見，請寫信給我們",
    },
    es: {
        language: "Idioma",
        SelectSystem:"Seleccionar sistema",
        DellARAssistantVideoPortal:"Portal de videos de asistente de realidad aumentada de Dell",
        Pleaseentertheauthenticationcodeyouhavereceived:"Por favor ingrese el código de autenticación que ha recibido",
        PleaseentercorrectPassword:"¡Por favor ingrese la contraseña correcta!",
        Confirm:"Confirmar",
        Resend:"Reenviar",
        Invalidurlpleasetrywithsomevalidurl:"URL inválida, por favor intente con una URL válida",
        series:"Serie",
        Partreplacement:"Reemplazo de piezas",
        videos:"Videos",
        Selectaparttopreviewthepartreplacementprocedure:"Seleccione una pieza para ver el procedimiento de reemplazo de piezas",
        Remove:"Quitar",
        Install:"Instalar",
        Step:"Paso",
        sharefeedback:"Compartir comentarios",
      appreciate:"Agradecemos sus valiosos comentarios, escríbanos a",
    },
    ja: {
        language: "言語",
        SelectSystem:"システムを選択",
        DellARAssistantVideoPortal:"Dell ARアシスタントビデオポータル",
        Pleaseentertheauthenticationcodeyouhavereceived:"受け取った認証コードを入力してください",
        PleaseentercorrectPassword:"正しいパスワードを入力してください！",
        Confirm:"確認する",
        Resend:"再送信する",
        Invalidurlpleasetrywithsomevalidurl:"無効なURLです。有効なURLを入力してください",
        series:"シリーズ",
        Partreplacement:"部品交換",
        videos:"ビデオ",
        Selectaparttopreviewthepartreplacementprocedure:"部品の交換手順をプレビューする部品を選択してください",
        Remove:"取り外す",
        Install:"取り付ける",
        Step:"ステップ",
        sharefeedback:"フィードバックを共有する",
      appreciate:"貴重なご意見をお待ちしております。下記までご連絡ください。",
    },
    pt: {
        language: "Linguagem",
        SelectSystem:"Selecionar Sistema",
        DellARAssistantVideoPortal:"Dell AR Assistant Video Portal",
        Pleaseentertheauthenticationcodeyouhavereceived:"Por favor, digite o código de autenticação que você recebeu",
        PleaseentercorrectPassword:"Por favor, digite a senha correta!",
        Confirm:"Confirmar",
        Resend:"Reenviar",
        Invalidurlpleasetrywithsomevalidurl:"URL inválida, tente com alguma URL válida",
        series:"Série",
        Partreplacement:"Substituição de peças",
        videos:"Vídeos",
        Selectaparttopreviewthepartreplacementprocedure:"Selecione uma peça para visualizar o procedimento de substituição da peça",
        Remove:"Remover",
        Install:"Instalar",
        Step:"Passo",
        sharefeedback:"Compartilhar comentários",
      appreciate:"Agradecemos o seu valioso feedback, escreva-nos para",
    },
  };
  