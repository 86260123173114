
import '../assets/css/main.css';
import '../assets/css/bootstrap.min.css';
import React, { Component } from "react";

import systerListHeader from "../assets/images/systerListHeader.png";
import systerListHeader2 from "../assets/images/systerListHeader2.png";
import menuIconNew from "../assets/images/menuIconNew.png";
import homeIconNew from "../assets/images/homeIconNew.png";
import lock_blue from "../assets/images/lock_blue.png";
import {translations} from '../utils/Translation';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import $ from 'jquery';
import withNavigate from '../utils/withNavigate';




const url= 'https://dellarassistantapi.kaalo.com'
// const filterUrl= 'https://dellarsupportapi.kaalo.com'
//import * as data from 'https://video-content-bucket-us-east-2-prod.s3.us-east-2.amazonaws.com/VideoTool_DellARAssistant.json';
const config = {
  attributes: {
    disablePictureInPicture: true,
    controlsList: "nodownload",
  },
};
// const navigate = useNavigate();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password:'',
      isAuthendicate:false,
      selectedLang:'en',
      nav_selected: "home",
      isClicked: false,
      selectedItem: 0,
      selectedSequence: 0,
      sequenceId: 1,
      isRemove: true,
      currentIndex: 0,
      isLoading: true,
      isPlaying: false,
      isMute: false,
      videoUri: "",
      video: {
        src: "http://www.example.com/path/to/video.mp4",
        poster: "http://www.example.com/path/to/video_poster.jpg",
      },
      modelName: "",
      stepName: "",
      stepListarray: [],
      sequnenceList: [],
      data: [],
      fulldata: [],
      VideoSource: "",
      CategoryName: "",
      productList: [],
      modalList: [],
      isContentLoading: true,
      isChecked: false,
      menuOpen: false,
      selectedmenu: 1,
      homeMenuPage: true,
      isnextPage:false,
      subModalArray:[],
      subModalName:'',
      selectedLang:'en',
      sysUniqId:'',
      enterTime:'',
      windowWidth:0
    };
  }
  resize() {
    // console.log('fhdgbvfg',window.innerWidth)
    this.setState({windowWidth: window.innerWidth});
    this.oWindowResize()
   
}
  componentDidMount = async() => {
    window.speechSynthesis.cancel();
    let sessionlan=window.sessionStorage.getItem("sessionlan");
    console.log('sessionlan',sessionlan)
    if(sessionlan){
      this.setState({
        selectedLang:sessionlan
      })
    }
   
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();  
    const value = localStorage.getItem('systemUniqueId');
    // console.log('acvhagcd',value)
    if(value){
      this.storeAction();
      // console.log('acvhagcd1',value)
    }else{
      let deviceUUID = uuidv4();
      localStorage.setItem('systemUniqueId', deviceUUID);
      this.storeAction();
    }
    this.getFilterData(this.state.isChecked)
    
  };

  oWindowResize = () => {
    var width = $(window).width();
    var height = $(window).height()
    if (width < 1023) {
      var scaleWidth = $(window).width() / 1080;
      var scaleHeight = $(window).height() / 1920;

      var currrentScaleValue = 0;
      if (scaleWidth < scaleHeight) {
        $(".appMainContainer").css('transform', 'scale(' + scaleWidth + ',' + scaleWidth + ')');
        currrentScaleValue = scaleWidth;
      }
      else {
        $(".appMainContainer").css('transform', 'scale(' + scaleWidth + ',' + scaleWidth + ')');
        currrentScaleValue = scaleWidth;
      }

      window["currrentScaleValue"] = currrentScaleValue;
      var left = $(window).width() / 2 - $(".appMainContainer").width() / 2
      var top = $(window).height() / 2 - $(".appMainContainer").height() / 2
      top = top / (4 * scaleWidth)
      $(".appMainContainer").css('left', left);
      let val = (left * 2) + (100 - (((width / 100) * 10) + (width / 100)));
      $(".appMainContainer").css('top', (val + 19));
      var cssObject = $('.appMainContainer').prop('style');
      // cssObject.removeProperty('right');

    }
  }
  storeAction = async (actionData) => {
    let value = localStorage.getItem('systemUniqueId');
    let timestamp1= new Date().toString();
    const dateString = moment(timestamp1).format('YYYY-MM-DD HH:mm:ss');
    // console.log('dateString',dateString)
    this.setState({sysUniqId:value,enterTime:dateString})
  //   await setDoc(doc(db, value, timestamp1), {
  //     name: "Los Angeles",
  //     state: "CA",
  //     country: "USA"
  //   });
  };
  componentWillUnmount() {
    // window.speechSynthesis.cancel();
    window.removeEventListener("resize", this.resize.bind(this));
    // window.removeEventListener('beforeunload', this.handleUnload);
    // window.removeEventListener('focus', this.myFunction);
  }
  handleUnload = (event) => {
// Cancel the event as stated by the standard.
event.preventDefault();
// Chrome requires returnValue to be set.
event.returnValue = '';

this.getFilterData();

    // Perform any cleanup or additional logic here
    // console.log("Browser tab is closing!");
    // console.log("Browser tab is closing123!");
    // window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=AR.Assistant@Dell.com`);
    // console.log("Browser tab is closing1234!");
    // If you want to show a confirmation message to the user, you can use the following line
    // event.returnValue = 'Are you sure you want to leave?';
  };
  // myFunction = () => {
  //   console.log('sdgvsdgvcsd')
  //   let language=window.sessionStorage.getItem("language");
  //     this.setState({
  //       selectedLang:language?language:'en'
  //     })
  // };

  getFilterData(isChecked) {
    let filterUrl='https://dellarsupportapi.kaalo.com/VideoTool_DellARAssistant'
    // if(isChecked){
    //   filterUrl=' https://testdellarsupportapi.kaalo.com/VideoTool_DellARAssistant_Local'
    // }
    fetch(`${filterUrl}/filter`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        let Promise = await response.json();
        // console.log('hhgsvghsdcd',Promise)
        let modelValue = Promise[0].products[0].ModelName.replaceAll(/\s/g, "");
        
        this.setState({modelValue:Promise[0].CategoryName,activeCategories:Promise[0].products,isLoading:false,productList:Promise,modalList:Promise[0].products})
        
      })
      .then((err) => {
        // console.log("datazdgfhgk2:", err);
      });

  }
  onProductChange = (item, index) => {
   
    this.setState({isSubModal:false,modelValue:item.CategoryName,modalList:item.products,selectedItem:index})
  }
  async onValidate(item){
    // console.log('dfhbvdjf',item)
  if(item.isSubCategoty){
    this.setState({fullModal:item,isSubModal:true,subModalArray:item.SubCategotyData,subModalName:item.displayName})
  }else{
    this.onRedirect(item,false,'')
    this.setState({isSubModal:false,subModalArray:[]})
  }
  }
  async onRedirect(item,isSubModl,fullModel) {
    
    let body = {
      "username": 'admin',
      "password": 'Vx6rRwYaNCyE6dcN',
    }
    
    
    
    fetch(`${url}/api/token`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        let fullData=await response.json()
        this.setState({accessToken:fullData.accessToken})
        this.getData(fullData.accessToken,item,isSubModl,fullModel)
        ////console.log('sdvhshdchsdchs3,',await response.json())
       // let data = await response.json();
       
      })
      .then(async (data) => {
        // let data2 = await data.json();
        // //console.log("datazdgfhgk112:", data2);
      })
      .catch(e => {
        //// console.log('sdvhshdchsdchs4,',e)
      });
  }

  async getData(accessToken,item,isSubModl,fullModel) {
    // console.log('hhvbsjdv',accessToken)
    // console.log('hhvbsjdv1',fullModel)
    let modelValue=''
    if(isSubModl){
      modelValue = item.subCatModel
    }else{
      modelValue = item.ModelName.replaceAll(/\s/g, "");
    } 
    // let timeModel=this.state.enterTime+" "+modelValue
    //   await setDoc(doc(db, this.state.sysUniqId, this.state.enterTime,modelValue), {
    //     modelValueName:modelValue,
    //     language: this.state.selectedLang,
    //   });
    
    let body = {
      "modelName": modelValue,
      "languageCode": this.state.selectedLang,
    }
    let value=accessToken
    fetch(`${url}/api/GetTempURL`, {
      method: 'POST',
      headers: {
        accesstoken:value,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        let fullData=await response.json()
        console.log('sjcbjdsbcvs',fullData)
        if(isSubModl){
            console.log('sjcbjdsbcvs1',fullData)
            // const params = new URLSearchParams({productName:fullData.temp_url,
            //     isSubModal:true,
            //     modalArray:this.state.fullModal,
            //     subModalName:item.subCatModel,
            //     fullModal:this.state.fullModal,
            //     sysUniqId:this.state.sysUniqId,
            //     enterTime:this.state.enterTime,
            //     modelValue:modelValue+','+this.state.selectedLang+','+'TechDirect',
            //     languageType:this.state.selectedLang });
                const { navigate } = this.props;
            //     navigate(`/product_name?${params}`);
            navigate('/product_name',
          {state: {
            productName:fullData.temp_url,
            isSubModal:true,
            modalArray:this.state.fullModal,
            subModalName:item.subCatModel,
            fullModal:this.state.fullModal,
            sysUniqId:this.state.sysUniqId,
            enterTime:this.state.enterTime,
            modelValue:modelValue+','+this.state.selectedLang+','+'TechDirect',
            languageType:this.state.selectedLang
          }})
        }else{
            // console.log('sjcbjdsbcvs2',this.props)
            // try{
            //     const params = new URLSearchParams({productName:fullData.temp_url,
            //         sysUniqId:this.state.sysUniqId,
            //         enterTime:this.state.enterTime,
            //         modelValue:modelValue+','+this.state.selectedLang+','+'TechDirect',
            //         languageType:this.state.selectedLang});
            //         const { navigate } = this.props;
            //         navigate(`/product_name?${params}`);
            // }catch(e){
            //     console.log('sjcbjdsbcvs3',e)
            // }
            const { navigate } = this.props;
            navigate('/product_name',
         {state: {
            productName:fullData.temp_url,
            sysUniqId:this.state.sysUniqId,
            enterTime:this.state.enterTime,
            modelValue:modelValue+','+this.state.selectedLang+','+'TechDirect',
            languageType:this.state.selectedLang
          }})
        }
        
        // this.setState({accessToken:fullData.accessToken})
        // this.getData(fullData.accessToken)
       // console.log('sdvhshdchsdchs3,',fullData)
       // let data = await response.json();
       
      })
      .then(async (data) => {
        // let data2 = await data.json();
        // //console.log("datazdgfhgk112:", data2);
      })
      .catch(e => {
        //console.log('sdvhshdchsdchs4,',e)
      });
  }

  redirectToGmail() {
    // console.log("Browser tab is closing123!");
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=AR.Assistant@Dell.com`);
  }

  render() {
    // console.log('ghsvhs',this.state.windowWidth)
    let auth=window.sessionStorage.getItem("authendicate");
    let mailtoHref = "mailto:support@example.com?subject=SendMail&body=Description";
    return (
      
      <div className="container-fluid  body-content ">
        
        <React.Fragment>
         
          <div className="bghomePage">
            
            {auth?
            <React.Fragment>
              {this.state.windowWidth>1023?
            <React.Fragment>
              <div className="row">
              <div className="col-12 p-0">
                <img src={systerListHeader} className="systerListHeader" />
              </div>
            </div>
            <select className="selectBoxPro"
            value={this.state.selectedLang}
            onChange={(e) => {
              window.sessionStorage.setItem("sessionlan", e.target.value);
              // console.log("lan",e.target.value)
              this.setState({selectedLang:e.target.value})
              }}>
                  <option value="en">English</option>
                  <option value="ccc">中文</option>
                  <option value="es">Español</option>
                  <option value="ja">日本語</option>
                  <option value="pt">Português</option>
            </select>
            <div className="selectBoxTxt">{translations[this.state.selectedLang].language}</div>
            <br/>
            <div className="row homeDummyheader4"></div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-3">
                <div className="clmrow1">
                  <div className="clmrow1Header">{translations[this.state.selectedLang].SelectSystem}</div>
                  {this.state.productList.map((item, index) => (
                  <div
                  onClick={() => {
                    this.onProductChange(item, index);
                  }}
                  className={this.state.selectedItem==index?"clmContent clm1active":"clmContent"}>{item.CategoryName}</div>
                  ))}
                
                </div>
              </div>
              {!this.state.isnextPage?
              <div className="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-3">
                <div className="clmrow2">
                  <div className="clmrow2Header">{this.state.modelValue}</div>
                  {this.state.modalList.map((item, index) => (
                  <div
                  onClick={() => {
                    this.onValidate(item)
                   
                   
                  }}
                  className="clmContent">{item.displayName}</div>
                  ))}
                 
                </div>
              </div>
              :
              <div className="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-3">
                <div className="clmrow2">
                  <div className="clmrow2Header">Optiplex</div>
                  <div className="clmContent active">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                  <div className="clmContent">OptiPlex</div>
                </div>
              </div>}
              {this.state.isSubModal?
              <div className="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-3">
                
                <div className="clmrow2">
                  <div className="clmrow2Header">{this.state.subModalName}</div>
                
                  {this.state.subModalArray.subCategoryList.map((item, index) => (
                  <div
                  onClick={() => {
                    this.onRedirect(item,true,this.state.subModalArray)
                   
                    
                  }}
                  className="clmContent">{item.subCatName }</div>
                  ))}
                 
                </div> 
              </div>
              :
              null}
             
            </div>
            <div className="feedback">{translations[this.state.selectedLang].sharefeedback}</div>
            <div className="mailrow">
            <div className="sendmailcontent">{translations[this.state.selectedLang].appreciate} </div>
            <div onClick={() => {this.redirectToGmail()}} className="sendmail">AR.Assistant@Dell.com</div>
            </div>
            </React.Fragment>:
              <div className="appMainContainer">
    <div className="contentArea">
      <img src={systerListHeader2} className="topImg1" />
      <div className="selectBoxArea1">
        <img onClick={() => {
            sessionStorage.clear();
            const { navigate } = this.props;
            navigate(`/product_name`);
            // this.props.history.push({pathname: '/',})
          }} src={homeIconNew} className="menuIconNew" />
        <img src={menuIconNew} className="homeIconNew dispalyNone" />
        <div className="LanguageTxt">Language</div>
        <select className="selectBoxlang">
          <option value="en">English</option>
          <option value="ccc">中文</option>
          <option value="es">Español</option>
          <option value="ja">日本語</option>
          <option value="pt">Português</option>
        </select>
      </div>
      <div className="selectBoxArea2 ">
        <div className="SelectSystemTxt">Select System</div>
        <select className="SelectSystem"
        onChange={(e) => {
          // console.log('hjbvhfdv',e.target.value)
          this.state.productList.map((item, index) => {
            if(e.target.value==item.CategoryName){
              this.onProductChange(item, index);
            }
          })
          
          // this.onProductChange(item, index);
          }}>
            <option value='Select'>Select Category</option>
        {this.state.productList.map((item, index) => (
          <option value={item.CategoryName}>{item.CategoryName}</option>
                 
        ))}
        </select>
        {/* <div
                  onClick={() => {
                    this.onProductChange(item, index);
                  }}
                  className={this.state.selectedItem==index?"clmContent clm1active":"clmContent"}>{item.CategoryName}</div>
                  ))} */}

        <div className="ChoosemodelTxt">Choose model</div>
        <select className="Choosemodel"
        onChange={(e) => {
          this.state.modalList.map((item, index) => {
            if(e.target.value==item.ModelName){
              this.onValidate(item)
            }
          })
          // this.onValidate(item)
          }}>
           <option value='Select'>Select Model</option>
        {this.state.modalList.map((item, index) => (
          <option value={item.ModelName}>{item.ModelName}</option>
                 
        ))}
        </select>
      </div>
      
    </div >
  </div >}
            </React.Fragment>:
             <React.Fragment>
               <div className="row">
              <div className="col-12 p-0">
                <img src={systerListHeader} className="systerListHeader" />
              </div>
            </div>
        <div style={styles.container}>
        <div style={styles.formContainer}>
        <img src={lock_blue} className="lock_blue" />
          <div className="loginheader">{translations[this.state.selectedLang].DellARAssistantVideoPortal}</div>
          <div className="loginTxt1"> {translations[this.state.selectedLang].Pleaseentertheauthenticationcodeyouhavereceived}</div>
          <form style={styles.form}>
            <input
              type="password"
              placeholder=""
              className="loginPass"
              onChange={(e) => {
                this.setState({password:e.target.value})
                }}
            />
            <button className={this.state.password.length==0?"button1 disabled":"button1"} onClick={() => {
              if(this.state.password.length!=0){
                if(this.state.password=='ARA@Dell'){
                  
                 
                  window.sessionStorage.setItem("authendicate", true);
                }else{
                  alert(translations[this.state.selectedLang].PleaseentercorrectPassword);
                }
              }
                    
                  }}  style={styles.button}>
              {translations[this.state.selectedLang].Confirm}
            </button>
            <button className="button2" style={styles.button}>
            {translations[this.state.selectedLang].Resend}
            </button>
          </form>
        </div>
      </div>
      </React.Fragment>}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  formContainer: {
    width: '300px',
    padding: '20px',
    // backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '3px',
    border: 'none',
  }
};

export default withNavigate(App);
