
import '../assets/css/main.css';
import '../assets/css/bootstrap.min.css';
import React, { Component } from "react";
import dellLogo1 from "../assets/images/Dell Technologies_logo.png";
import dellLogo2 from "../assets/images/Dell AR Assistant_logo.png";
import logo1 from "../assets/images/Latitude3520_1.png";
import logo2 from "../assets/images/Remove_Off.png";
import logo3 from "../assets/images/Remove_On.png";
import logo4 from "../assets/images/Install_Off.png";
import logo5 from "../assets/images/Install_On.png";
import logo6 from "../assets/images/LOCATE_ON.png";
import logo7 from "../assets/images/SCREWS_ON.png";
import logo8 from "../assets/images/PART_ON.png";
import logo9 from "../assets/images/AR_Icon.png";
import logo10 from "../assets/images/CABLES_ON.png";
import logo11 from "../assets/images/LATCH_ON.png";
import logo12 from "../assets/images/VIDEO_ON.png";
import ReactPlayer from "react-player";
import { GridLoader } from "react-spinners";
import Device from 'react-device'
import { getAnalytics, logEvent } from "firebase/analytics";
import uuid from 'react-uuid';
import {translations} from '../utils/Translation';
import {db} from '../utils/config'
import {setDoc,doc} from "firebase/firestore"
import moment from 'moment';
import systerListHeader2 from "../assets/images/systerListHeader2.png";
import menuIconNew from "../assets/images/menuIconNew.png";
import homeIconNew from "../assets/images/homeIconNew.png";
import $ from 'jquery';
import withNavigate from '../utils/withNavigate';
//var crypto = require('crypto');
const encode = require('nodejs-base64-encode');
window.Buffer = window.Buffer || require("buffer").Buffer;

const url= 'https://dellarassistantapi.kaalo.com'
let windowWidth=0
let isVideoChange=false
let setSpeaking=true
// need to updte
let urlIndex=0
let isReady=false
let isVideoChangeCheck=true


//import * as data from 'https://video-content-bucket-us-east-2-prod.s3.us-east-2.amazonaws.com/VideoTool_DellARAssistant.json';
const config = {
  attributes: {
    disablePictureInPicture: true,
    controlsList: "nodownload",
  },
};

// const userDetails = collection(db,"user")
class App extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
      nav_selected: "home",
      isClicked: false,
      selectedItem: 0,
      selectedSequence: 0,
      sequenceId: 1,
      isRemove: true,
      currentIndex: 0,
      isLoading: true,
      isPlaying: true,
      isMute: false,
      videoUri: "",
      video: {
        src: "http://www.example.com/path/to/video.mp4",
        poster: "http://www.example.com/path/to/video_poster.jpg",
      },
      modelName: "",
      stepName: "",
      stepListarray: [],
      sequnenceList: [],
      data: [],
      fulldata: [],
      VideoSource: "",
      CategoryName: "",
      isInvalid:false,
      isVisible:false,
      dropdownData:[],
      isDropdownLoading:true,
      dropdownName:'',
      accessToken:'',
      uuid:'',
      isFirstTimeLoad:false,
      timer:0,
      path_array1:[],
      uniqueId:'',
      languageCode:'en',
      languageType:'en',
      lastTime:0,
      ref_time:'',
      windowWidth:0,
      ismenu:false,
      setSpeaking:true,
      isVideoChange:false,
      urlarray:[],
      urlIndex:0,
      engStepList:[],
    };
  }
  componentWillUnmount = async() => {
    window.removeEventListener("resize", this.resize.bind(this));
    const analytics = getAnalytics();
       logEvent(analytics, 'page_end',{time_in_sec:this.state.timer,product_name: this.state.path_array1[0],
       product_language: this.state.path_array1[1]});
  }
  timer() {
    this.setState({
      timer: this.state.timer + 1
    })
    
  }

  oWindowResize = () => {
    var width = $(window).width();
    windowWidth=width
    var height = $(window).height()
    if (width < 1023) {
      var scaleWidth = $(window).width() / 1080;
      var scaleHeight = $(window).height() / 1920;

      var currrentScaleValue = 0;
      if (scaleWidth < scaleHeight) {
        $(".appMainContainer").css('transform', 'scale(' + scaleWidth + ',' + scaleWidth + ')');
        currrentScaleValue = scaleWidth;
      }
      else {
        $(".appMainContainer").css('transform', 'scale(' + scaleWidth + ',' + scaleWidth + ')');
        currrentScaleValue = scaleWidth;
      }

      window["currrentScaleValue"] = currrentScaleValue;
      var left = $(window).width() / 2 - $(".appMainContainer").width() / 2
      var top = $(window).height() / 2 - $(".appMainContainer").height() / 2
      top = top / (4 * scaleWidth)
      $(".appMainContainer").css('left', left);
      let val = (left * 2) + (100 - (((width / 100) * 10) + (width / 100)));
      $(".appMainContainer").css('top', (val + 19));
      var cssObject = $('.appMainContainer').prop('style');
      // cssObject.removeProperty('right');

    }
  }
  resize() {
    this.setState({windowWidth: window.innerWidth});
    this.oWindowResize()
    // console.log('fhdgbvfg',window.innerWidth)
}
  componentDidMount = async() => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();  
    const { location } = this.props;
    const productName = location.state?.productName
    const sysUniqId = location.state?.sysUniqId
    const enterTime = location.state?.enterTime
    const modelValue = location.state?.modelValue
    const languageType = location.state?.languageType
    this.setState({languageType:languageType,sysUniqId:sysUniqId,enterTime:enterTime,propsModel:modelValue})
   this.intervalId = setInterval(this.timer.bind(this), 1000);
   
    let body = {
      "username": 'admin',
      "password": 'Vx6rRwYaNCyE6dcN',
    }
    
    
    
    fetch(`${url}/api/token`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        ////console.log('sdvhshdchsdchs,',await response.json())
        let fullData=await response.json()
        //console.log('sdvhshdchsdchs1,',fullData)
        this.setState({accessToken:fullData.accessToken})
       // //console.log('sdvhshdchsdchs2,',await response.json())
        this.getData(fullData.accessToken)
        ////console.log('sdvhshdchsdchs3,',await response.json())
       // let data = await response.json();
       
      })
      .then(async (data) => {
        // let data2 = await data.json();
        // //console.log("datazdgfhgk112:", data2);
      })
      .catch(e => {
        //console.log('sdvhshdchsdchs4,',e)
      });
    //  this.checkurlPath()
  };
  async getData(accessToken) {
    ////console.log("datazdgfhgk134567:", window.location.pathname); // output 'testing'
    
    //console.log('pathname',accessToken)
   // var path = this.props.history.location.state?.productName;
    //console.log('pathname',path)
    const { location } = this.props;
    var path_array = location.state?.productName;
    var isSubModal = location.state?.isSubModal;
    var modalArray = location.state?.modalArray;
    var subModalName = location.state?.subModalName;
    var fullModal = location.state?.fullModal;
    // console.log('pathname1245',path_array)
    let pathname=encode.decode(path_array, 'base64')
    // console.log('pathname1',pathname)
    let path_array1 = pathname.split("_");
    // console.log('pathname1123',path_array1)
    
    if(!this.state.isFirstTimeLoad){
     
      let uniqueId=uuid()
      this.setState({isFirstTimeLoad:true,path_array1:path_array1,uniqueId:uniqueId})
      // console.log('pathname12',uniqueId)
      const analytics = getAnalytics();
     /// setUserProperties(analytics, { favorite_food: 'apples' });
       logEvent(analytics, 'page_open',{type:'TechDirect',product_name: path_array1[0],
       product_language: path_array1[1],uniqueId:uniqueId});
       
    }
    
    // logEvent(analytics, 'select_content', {
    //   content_type: 'image',
    //   content_id: 'P12453'
    // });
    // logEvent(analytics,'select_content',{product_language:path_array1[1]})
    // console.log('pathname1234',test)
    //analytics.logEvent('count');
    // logEvent(analytics, path_array1[0]);
    // logEvent(analytics, 'page_view_count');
    // logEvent(analytics, 'DTC');
    // logEvent(analytics, path_array1[0]);
     //languageEvent={'open':{'language':path_array1[1],'type':'DTC','product_name':path_array1[1]}}
     
    //console.log('pathname',path_array)
    // var path_array1 = "";
    // pathname.map((item, index) => {
    //   path_array1 = item.split("_");
    // });

    // const getData = async () => {
    //   const res = await axios.get('https://geolocation-db.com/json/')
    //   //console.log(res.data);
    //   setIP(res.data.IPv4)
    // }

    
    // console.log('pathname3',path_array1)
    // console.log('pathname4',this.state.uuid)
    fetch(`https://dellarassistantapi.kaalo.com/api/VideoTool_DellARAssistant?timestamp=${path_array1[2]}&deviceId=${this.state.uuid}&tempURL=${path_array}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accesstoken":accessToken,
      },
    })
      .then(async (response) => {
        let Promise = await response.json();
        //console.log("datazdgfhgknew02:", response);
       // //console.log('sdvhshdchsdchs,1',await response.json())
        if(Promise.message){
          this.setState({isInvalid:true})
          //console.log("datazdgfhgknew02:", response);
        }else{
          //console.log("datazdgfhgknew01:", Promise);
        let filterValue = path_array1[0].replaceAll(/\s/g, "");
        let modelValue = "";
        //  console.log('pathname1',path_array1)
        if(isSubModal){
          // console.log('pathname111',path_array1)
          this.fetchData(subModalName, path_array1, modalArray,true);
        }else{

        
        Promise.ProductList.map((item, index) => {
          
          //console.log("datazdgfhgknew030:", item);
          if(item.isSpecial){
            let filterValue = `${path_array1[0].replaceAll(/\s/g, "")}_${path_array1[1].replaceAll(/\s/g, "")}`;
            if (
              item.ModelName.replaceAll(/\s/g, "").toUpperCase() ==
              filterValue.toUpperCase()
            ) {
              //  console.log('pathname21',item.ModelName.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
              modelValue = item.ModelName.replaceAll(/\s/g, "");
               //console.log('pathname3',path_array1)
              this.fetchData(modelValue, path_array1, item);
              //console.log("datazdgfhgknew03:", modelValue,path_array1);
            }
            //console.log("filterValuefilterValue:", filterValue);
          }else if(isSubModal){
            //  console.log('pathname22',item.Product.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
            // if (
            //   item.Product.replaceAll(/\s/g, "").toUpperCase() ==
            //   filterValue.toUpperCase()
            // ) {
            //  //  console.log('pathname23',item.Product.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
            //   modelValue = item.SubCategotyData.subCategoryList[0].subCatModel;
            //   // console.log('pathname24',item.SubCategotyData.subCategoryList[0].subCatModel)
            //   this.setState({dropdownName:item.SubCategotyData.subCategoryHeaderName,path_array1:path_array1,item:item,isVisible:true,dropdownData:item.SubCategotyData.subCategoryList})
            //   this.fetchData(modelValue, path_array1, item);
            //   //console.log("datazdgfhgknew03:", modelValue,path_array1);
            // }
          }else {
            // console.log('pathname23',item.ModelName.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
           ////console.log("datazdgfhgknew031:", item.ModelName.replaceAll(/\s/g,''),filterValue);
          if(item.ModelName!=null){
            if (
              item.ModelName.replaceAll(/\s/g, "").toUpperCase() ==
              filterValue.toUpperCase()
            ) {
              //  console.log('pathname21',item.ModelName.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
              modelValue = item.ModelName.replaceAll(/\s/g, "");
               //console.log('pathname3',path_array1)
              this.fetchData(modelValue, path_array1, item);
              //console.log("datazdgfhgknew03:", modelValue,path_array1);
            }
          }
           
         } 
        });
        }
      }
        
      })
      .then((err) => {
       //console.log("datazdgfhgk2:", err);
      });

  }

  fetchData(modelValue, path_array1, item,isSubModal) {
    // console.log("datazdgfhgknew02fghj:", item);
    fetch(`https://dellarassistantapi.kaalo.com/api/arassistant?model_name=${modelValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accesstoken":this.state.accessToken,
      },
    })
      .then(async (response) => {
        let Promise = await response.json();
        //console.log("datazdgfhgknew02:", Promise);
        let currentIndex = 0;

        let language=path_array1.length==4?path_array1[2].toLowerCase():path_array1[1].toLowerCase()
       // //console.log("datazdgfhgknew021:", path_array1[1]);
        if (language) {
          if (language.toUpperCase() == "EN") {
            currentIndex = 0;
          } else if (language.toUpperCase() == "ES") {
            currentIndex = 3;
          } else if (language.toUpperCase() == "PT") {
            currentIndex = 6;
          } else if (language.toUpperCase() == "JA") {
            currentIndex = 5;
          } else if (language.toUpperCase() == "CCC") {
            currentIndex = 1;
          }
        }
        // console.log("testinresult1:",Promise.languageList[currentIndex].procedureList[0].sequnenceList);
        // console.log('hvjdvh',path_array1)
        // console.log('hvjdvh0',path_array1[2].toLowerCase())
        // console.log('hvjdvh1',modelValue)
        let VideoSource=item.VideoSource
        // if(isSubModal){
        //   item.SubCategotyData.subCategoryList.map((itm, index) => {
        //     if(itm.subCatModel==modelValue){
        //       let temp=itm.subCatName.replace(/\s/g, "")
        //       VideoSource=item.VideoSource+"_"+temp
        //     }
        //   })
        // }
        this.setState({
          languageCode:language,
          stepName:
            Promise.languageList[currentIndex].procedureList[0].procedureName,
          fulldata: Promise.languageList,
          sequnenceList:
          await this.getSequence(Promise.languageList[currentIndex].procedureList[0].sequnenceList) ,
          modelName: item.Product,
          data: Promise.languageList[currentIndex],
          currentIndex: currentIndex,
          VideoSource: VideoSource,
          CategoryName: item.CategoryName,
          ProductImgName:
            "https://video-content-bucket-us-east-2-prod.s3.us-east-2.amazonaws.com/ProductImgage/" +
            item.ProductImgName +
            ".png",
        });
        this.onselectStepListInitial(
          Promise.languageList[currentIndex].procedureList[0].sequnenceList[0]
            .sequenceId,
          this.state.isRemove
        );
      })
      .then((err) => {
       // //console.log("datazdgfhgk2:", err);
      });
  }

  onselectStepListInitial = async(item, isRemove) => {
  //  console.log("datazdgfhgknew023:",this.state.stepName);
  
    if (this.state.data.sequenceList) {
     // //console.log("datazdgfhgknew024:");
      let activeCategories = this.state.data.sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
    //  console.log("datazdgfhgknew025:",activeCategories);
      let fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
    //  console.log("datazdgfhgknew026:",fullActiveCategories);
      let isValidurl = true;
      let time=new Date()
        let timestamp1= time.toString();
      // let timestamp1= new Date().toString();
      
      const dateString = moment(timestamp1).format('YYYY-MM-DD HH:mm:ss');
      let uniqueName=this.state.sysUniqId+","+dateString
     
      this.setState({lastTime:time,ref_time:uniqueName})
      let procetureTime=this.state.stepName+","+dateString
      // console.log('shvjshvs1',this.state.sysUniqId, this.state.enterTime,this.state.propsModel,procetureTime)
      // let ModelValue=this.state.modelName+","+this.state.languageCode+","+'TechDirect'
      await setDoc(doc(db, this.state.sysUniqId, this.state.enterTime,this.state.propsModel,procetureTime), {
        sequenceName:activeCategories[0].sequneceName,
        instalationType:this.state.isRemove?'Remove':'Install'
      });
      
      //this.state.propsModel,procetureTime
      // await setDoc(doc(db, 'Analytics',uniqueName), {
      //   divisionType:'TechDirect',
      //   sequenceName:activeCategories[0].sequneceName,
      //   installationType:this.state.isRemove?'Remove':'Install',
      //   language:this.state.languageType,
      //   procedureName:this.state.stepName,
      //   productName:this.state.propsModel,
      //   timestamp:dateString,
      //   totalSpend:0,
      //   uniqueId:this.state.sysUniqId,
      // });

    //   const analytics = getAnalytics();
    // logEvent(analytics, 'sequence',{uniqueId:this.state.uniqueId,procedure_name:this.state.stepName,product_name: this.state.path_array1[0],
    // product_language: this.state.path_array1[1],sequence_name:activeCategories[0].sequneceName,sequence_type:this.state.isRemove?'remove':'install'});
      
    //  console.log("datazdgfhgknew027:",activeCategories[0].isOptional_Sequence);
      if (activeCategories[0].isOptional_Sequence) {
      //  console.log("datazdgfhgknew028:",activeCategories[0].optionSequenceIDList);
        if (activeCategories[0].optionSequenceIDList) {
        //  console.log("datazdgfhgknew029:");
          activeCategories = this.state.data.sequenceList.filter(
            (cat) =>
              cat.sequenceId == activeCategories[0].optionSequenceIDList[0]
          );
       //   //console.log("datazdgfhgknew030:",activeCategories);
          fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
            (cat) =>
              cat.sequenceId == fullActiveCategories[0].optionSequenceIDList[0]
          );
        //  //console.log("datazdgfhgknew031:",fullActiveCategories);
        } else {
         // //console.log("datazdgfhgknew032:",fullActiveCategories);
          isValidurl = false;
        }
      }
     // //console.log("datazdgfhgk11:", isRemove);
    //  console.log("datazdgfhgk111:", fullActiveCategories);
      if (isRemove) {
        let url =
          isValidurl && fullActiveCategories[0].removeVideoURl != ""
            ? fullActiveCategories[0].removeVideoURl
            : "dell";
      //  //console.log("dsvgcgsdhc0", url);
      //  //console.log("dsvgcgsdhc01", fullActiveCategories[0]);
        
      //  console.log("dsvgcgsdhc", activeCategories[0].removeStepList);
        let stepListarray = [];
        activeCategories[0].removeStepList.map((item, index) => {
          let value = item;
          value.stepCount = `${index + 1}/${
            activeCategories[0].removeStepList.length
          }`;
          
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });
        // let engStepList=[]
        // fullActiveCategories[0].removeStepList.map((item, index) => {
        //   let value = item;
        //   value.stepCount = `${index + 1}/${
        //     fullActiveCategories[0].removeStepList.length
        //   }`;
          
        //   if (item.isNestedStep) {
        //     item.nestedStepList.map((data, indx) => {
        //       let value = data;
        //       value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
        //       engStepList.push(data);
        //     });
        //   }else{
        //     engStepList.push(item);
        //   }
        // });
        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        if (this.player) {
          this.player.seekTo(0);
        } else {
          // this.onPressProcedure(this.state.data.procedureList[0],0);
        }
        // console.log('hvbjdhfd',stepListarray)
        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
          // engStepList:engStepList
        });
        // console.log('test2')
        this.setState({  isMute: true });
       // isVideoChangeCheck=true
        // this.setState({  isMute: true });
        setTimeout(() => {
          // this.setState({ isMute: true, isPlaying: true });
          this.setState({ isMute: true});
        }, 4000);
        
        // this.handleSpeak(stepListarray[urlIndex].stepInforTxt)
      } else {
        let url =
          isValidurl && fullActiveCategories[0].installVideoURl != ""
            ? fullActiveCategories[0].installVideoURl
            : "dell";

        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        this.player.seekTo(0);
        let stepListarray = [];
        activeCategories[0].installStepList.map((item, index) => {
          let value = item;
          value.stepCount = `${index + 1}/${
            activeCategories[0].installStepList.length
          }`;
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });

        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
        });
      }
    }else{
      this.getData(this.state.accessToken)
    }
  };
  
  checkurlPath() {
    ////console.log('this.state.data',this.state.data.languageList[0].sequenceList)
    this.state.data.sequenceList.map((item, index) => {
      

     
      //this.checkurl}
    });
  }
  // checkurl = async (url) => {
  //   this.checkFIle(
  //     "https://d3u57nouj7te3v.cloudfront.net/Latitude/" + url + ".mp4"
  //   )
  //     .then(() => {
  //      // //console.log("this.state.data1", url, true);
  //       return true;
  //     })
  //     .catch((err) => {
  //      // //console.log("this.state.data1", url, false);
  //       return false;
  //     });
  // };
  checkFIle = async (url) => {
    let check = await fetch(url, { method: "HEAD" });
   // //console.log("this.state.data2", url, check);
    return fetch(url, { method: "HEAD" });
  };

  getSequence = async (seq) => {
    let seqList=[]
    seq.map((item, index) => {
      seqList.push(item)
      if(item.isOption){
        // console.log('sdhcbus1',item.optionSeqList[0].optionSeqList)
        let checkBool=false
        item.optionSeqList[0].optionSeqList.map((itm, indx) => {
          
          if(itm.sequenceId!=0){
            seqList.push(itm)
          }else{
            checkBool=true
          }
          
        })
        if(checkBool){
          item.optionSeqList[1].optionSeqList.map((itm, indx) => {
          
            if(itm.sequenceId!=0){
              seqList.push(itm)
            }
            
          })
        }
        
      }
      
    })
    // console.log('sdhcbus',seqList)
    return seqList;
    
  };

  onPressProcedure = async (item, index) => {
    urlIndex=0
    window.speechSynthesis.cancel();
    this.setState({
     // setSpeaking:false -- need to updte
    })
    // console.log("testinresult2:",item.procedureName);
    // const dateString = moment(timestamp1).format('YYYY-MM-DD HH:mm:ss');
    
    // await setDoc(doc(db, this.state.sysUniqId, this.state.enterTime), {
    //   sequenceName:'Procedure',
    //   procedureName:item.procedureName,
    //   instalationType:this.state.isRemove?'Remove':'Install'
    // });
    let seqList=await this.getSequence(item.sequnenceList)
    // console.log('sdhcbus2',seqList)
    this.setState({
      stepName: item.procedureName,
      selectedSequence: 0,
      selectedItem: index,
      isRemove: true,
      sequnenceList:await this.getSequence(item.sequnenceList) ,
      // sequnenceList: this.getSequence(item.sequnenceList),
      sequenceId: this.state.isRemove
        ? item.sequnenceList[0].sequenceId
        : item.sequnenceList[0].sequenceId,
    });
    this.onselectStepList(
      item.sequnenceList[0].sequenceId,
      this.state.isRemove
    );
  };
  onPressSequence = (item, index) => {
    urlIndex=0
    this.setState({ selectedSequence: index, sequenceId: item.sequenceId });
    this.onselectStepList(item.sequenceId, this.state.isRemove);
    // const analytics = getAnalytics();
    // logEvent(analytics, 'sequence',{uniqueId:this.state.uniqueId,procedure_name:this.state.stepName,product_name: this.state.path_array1[0],
    // product_language: this.state.path_array1[1],sequence_name:item.sequenecName,sequence_type:this.state.isRemove?'remove':'install'});
  };
  onSequenceChange = (val) => {
    urlIndex=0
    // console.log("testinresult3:",val);
    if (val) {
      // console.log("dfhvbjdf1:", val);
     if(!this.state.isRemove){
       let sequence=this.state.sequnenceList.reverse()
       this.setState({sequnenceList:sequence})
      //  console.log("dfhvbjdf2:", sequence);
      //  this.onselectStepList(sequence[0].sequenceId, val);
     }else{
      //  console.log("dfhvbjdf3:", this.state.sequnenceList);
      //  this.onselectStepList(this.state.sequnenceList[0].sequenceId, val);
     }
      //console.log("dfhvbjdf1:", val);
     
     
     } else {
       if(this.state.isRemove){
         let sequence=this.state.sequnenceList.reverse()
         this.setState({sequnenceList:sequence})
        //  console.log("dfhvbjdf4:", sequence);
        //  this.onselectStepList(
        //    sequence[0]
        //      .sequenceId,
        //    val
        //  );
       }
       else{
        //  console.log("dfhvbjdf5:", this.state.sequnenceList);
        //  this.onselectStepList(
        //    this.state.sequnenceList[this.state.sequnenceList.length - 1]
        //      .sequenceId,
        //    val
        //  );
       }
       
      //  this.onselectStepList(
      //    this.state.sequnenceList[this.state.sequnenceList.length - 1]
      //      .sequenceId,
      //    val
      //  );
     }
    if (val) {
    //  console.log("dfhvbjdf6:", val);
      
      if(!this.state.isRemove){
        let sequence=this.state.sequnenceList
        this.setState({sequnenceList:sequence})
        // console.log("dfhvbjdf7:", sequence);
      }
      this.onselectStepList(this.state.sequnenceList[0].sequenceId, val);
      
    } else {
      if(this.state.isRemove){
        // console.log("dfhvbjdf81:", this.state.sequnenceList);
        let sequence=this.state.sequnenceList
        this.setState({sequnenceList:sequence})
        // console.log("dfhvbjdf8:", sequence);
      }
      this.onselectStepList(
        this.state.sequnenceList[0]
          .sequenceId,
        val
      );
    }
    this.setState({
      selectedSequence: 0,
      isRemove: val,
      sequenceId: val
        ? this.state.sequnenceList[0].sequenceId
        : this.state.sequnenceList[this.state.sequnenceList.length - 1]
            .sequenceId,
    });
  };
  onselectStepList = async(item, isRemove) => {
    // window.speechSynthesis.cancel();
    // this.setState({
    //   //setSpeaking:false -- need to updte
    // })
    // console.log("testinresult5:",this.state.sequnenceList);
   
    if (this.state.data.sequenceList) 
    {
      let activeCategories = this.state.data.sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
      let fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
      let isValidurl = true;
      // setTimeout(async() => {
      //   let time=new Date()
      //   let timestamp= time.toString();
      // const dateString = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
      // let procetureTime=this.state.stepName+","+dateString
      // let uniqueName=this.state.sysUniqId+","+dateString
      // console.log('shvhsvs',timestamp)
      // console.log('shvhsvs1',this.state.lastTime)
      // var dif = time.getTime() - this.state.lastTime.getTime();
      // await updateDoc(doc(db, 'Analytics',this.state.ref_time), {
        
      //   totalSpend:dif,
      // });
      // console.log('shvhsvs2',dif)
      // this.setState({lastTime:time,ref_time:uniqueName})
      // await setDoc(doc(db, 'Analytics',uniqueName), {
      //   divisionType:'TechDirect',
      //   sequenceName:activeCategories[0].sequneceName,
      //   installationType:this.state.isRemove?'Remove':'Install',
      //   language:this.state.languageType,
      //   procedureName:this.state.stepName,
      //   productName:this.state.propsModel,
      //   timestamp:dateString,
      //   totalSpend:0,
      //   uniqueId:this.state.sysUniqId,
      // });
      
        
      // }, 2000)
      setTimeout(async() => {
      let time=new Date()
      let timestamp1= time.toString();
      // let timestamp1= new Date().toString();
      
      const dateString = moment(timestamp1).format('YYYY-MM-DD HH:mm:ss');
      let procetureTime=this.state.stepName+","+dateString
      // console.log('shvjshvs2',this.state.sysUniqId, this.state.enterTime,this.state.propsModel,procetureTime)
      await setDoc(doc(db,this.state.sysUniqId, this.state.enterTime,this.state.propsModel,procetureTime), {
        sequenceName:activeCategories[0].sequneceName,
        instalationType:this.state.isRemove?'Remove':'Install'
      });
    }, 2000)
    //    const analytics = getAnalytics();
    // logEvent(analytics, 'sequence',{uniqueId:this.state.uniqueId,procedure_name:this.state.stepName,product_name: this.state.path_array1[0],
    // product_language: this.state.path_array1[1],sequence_name: activeCategories[0].sequneceName,sequence_type:this.state.isRemove?'remove':'install'});
      // console.log("dfhvbjdf4:", activeCategories[0].sequneceName);
      if (activeCategories[0].isOptional_Sequence) {
        if (activeCategories[0].optionSequenceIDList) {
          // console.log("testinresult8:",this.state.sequnenceList);
          activeCategories = this.state.data.sequenceList.filter(
            (cat) =>
              cat.sequenceId == activeCategories[0].optionSequenceIDList[0]
          );

          fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
            (cat) =>
              cat.sequenceId == fullActiveCategories[0].optionSequenceIDList[0]
          );
        } else {
          isValidurl = false;
        }
      }
      // console.log("dfhvbjdf43:", activeCategories);
      // console.log("dfhvbjdf44:", fullActiveCategories);
      if (isRemove) {
        let url =
         isValidurl && fullActiveCategories[0].removeVideoURl != ""
           ? fullActiveCategories[0].removeVideoURl
           : "dell";
      //  //console.log("dsvgcgsdhc0", url);
       // //console.log("dsvgcgsdhc01", fullActiveCategories[0]);
        
      //  //console.log("dsvgcgsdhc", activeCategories[0].removeStepList);
        let stepListarray = [];
        activeCategories[0].removeStepList.map((item, index) => {
          // console.log("testinresult7:",this.state.sequnenceList);
          let value = item;
          value.stepCount = `${index + 1}/${
            activeCategories[0].removeStepList.length
          }`;
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });
        // let engStepList=[]
        // fullActiveCategories[0].removeStepList.map((item, index) => {
        //   // console.log("testinresult7:",this.state.sequnenceList);
        //   let value = item;
        //   value.stepCount = `${index + 1}/${
        //     fullActiveCategories[0].removeStepList.length
        //   }`;
        //   if (item.isNestedStep) {
        //     item.nestedStepList.map((data, indx) => {
        //       let value = data;
        //       value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
        //       engStepList.push(data);
        //     });
        //   }else{
        //     engStepList.push(item);
        //   }
        // });
        // console.log('test4',urlIndex)
        // console.log('test11',engStepList)
        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        if (this.player) {
          this.player.seekTo(0);
        } else {
          // this.onPressProcedure(this.state.data.procedureList[0],0);
        }
        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
          // engStepList:engStepList
        });
       // isVideoChangeCheck=true
        // this.handleSpeak(stepListarray[urlIndex].stepInforTxt)
      } else {
        
        let url =
        isValidurl && fullActiveCategories[0].installVideoURl != ""
          ? fullActiveCategories[0].installVideoURl
          : "dell";

        let stepListarray = [];
        activeCategories[0].installStepList.map((item, index) => {
          // console.log("testinresult6:",this.state.sequnenceList);
          let value = item;
          value.stepCount = `${index+1} ${index + 1}/${
            activeCategories[0].installStepList.length
          }`;
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });
        // let engStepList=[]
        // fullActiveCategories[0].installStepList.map((item, index) => {
        //   // console.log("testinresult6:",this.state.sequnenceList);
        //   let value = item;
        //   value.stepCount = `${index+1} ${index + 1}/${
        //     fullActiveCategories[0].installStepList.length
        //   }`;
        //   if (item.isNestedStep) {
        //     item.nestedStepList.map((data, indx) => {
        //       let value = data;
        //       value.stepCount = `${indx + 1}/${item.nestedStepList.length}`;
        //       engStepList.push(data);
        //     });
        //   }else{
        //     engStepList.push(item);
        //   }
        // });
        // console.log('test3',urlIndex)
        let videoUri =
        "https://d3u57nouj7te3v.cloudfront.net/" +
        this.state.VideoSource +
        "/" +
        url +
        ".mp4";
      
        this.player.seekTo(0);
      
        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
          // engStepList:engStepList
        });
       // isVideoChangeCheck=true
        // this.handleSpeak(stepListarray[urlIndex].stepInforTxt)
      }
    }
  };
  handleSpeak = (text) => {
    // console.log('test21')
    if (text !== '') {
      let lang='en-US'
      let lanCode=this.state.languageCode
      if(lanCode=='pt'){
        lang='pt'
      }else if(lanCode=='ja'){
        lang='ja'
      }else if(lanCode=='es'){
        lang='pt'
      }else if(lanCode=='ccc'){
        lang='zh-CN'
      }
      // let val="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      const speech = new SpeechSynthesisUtterance(text);
      speech.lang = lang; // Set the language
      speech.onstart = () => {
        //isVideoChange=false
       // setSpeaking=false -- need to updte
        
      };

      speech.onend = () => {
        setSpeaking=true
        // console.log('test21',setSpeaking)
        this.onVideoChange();
        
      };
     // window.speechSynthesis.speak(speech);
    }
  };
  onVideoChange = () => {
    if (this.state.sequnenceList.length != this.state.selectedSequence + 1) {
      
      this.onselectStepList(
        this.state.sequnenceList[this.state.selectedSequence + 1].sequenceId,
        this.state.isRemove
      );
      this.setState({
        selectedSequence: this.state.selectedSequence + 1,
        sequenceId: this.state.sequnenceList[this.state.selectedSequence + 1],
      });
      //this.onPressProcedure(this.state.sequnenceList[this.state.selectedSequence+1],this.state.selectedSequence+1)
      // this.setState({selectedSequence:this.state.selectedSequence+1,videoUri:'https://d3u57nouj7te3v.cloudfront.net/Latitude/Latitude_3520_Battery_Cable_R_02.mp4'})
    }
  //  --- video voice code ----
  //   if(setSpeaking && isVideoChange){
  //     urlIndex=urlIndex+1
  //   // console.log('test0',urlIndex,this.state.stepListarray.length-1)
  //   if(urlIndex>this.state.stepListarray.length-1){
  //     // console.log('test3',this.state.urlIndex)
  //     if (this.state.sequnenceList.length != this.state.selectedSequence + 1) {
       
  //       urlIndex=0
  //       this.onselectStepList(
  //         this.state.sequnenceList[this.state.selectedSequence + 1].sequenceId,
  //         this.state.isRemove
  //       );
  //       this.setState({
  //         selectedSequence: this.state.selectedSequence + 1,
  //         sequenceId: this.state.sequnenceList[this.state.selectedSequence + 1],
  //       });
  //       //this.onPressProcedure(this.state.sequnenceList[this.state.selectedSequence+1],this.state.selectedSequence+1)
  //       // this.setState({selectedSequence:this.state.selectedSequence+1,videoUri:'https://d3u57nouj7te3v.cloudfront.net/Latitude/Latitude_3520_Battery_Cable_R_02.mp4'})
  //     }
  //   }else{
  //     // console.log('test1',urlIndex)
  //     // console.log('test2',urlIndex)
  //     let videoUri =
  //         "https://d3u57nouj7te3v.cloudfront.net/" +
  //         this.state.VideoSource +
  //         "/" +
  //         this.state.engStepList[urlIndex].stepURl +
  //         ".mp4";
  //       if (this.player) {
  //         this.player.seekTo(0);
  //       } else {
  //         // this.onPressProcedure(this.state.data.procedureList[0],0);
  //       }
       
  //       this.setState({
  //         isLoading: false,
  //         videoUri: videoUri,
  //       });
  //       isVideoChangeCheck=true
  //       // this.handleSpeak(this.state.stepListarray[urlIndex].stepInforTxt)
  //   }
  // }else{
  //   if(isVideoChange){
  //     // console.log('test1',urlIndex)
  //     // console.log('test11',this.state.engStepList)
  //     isVideoChange=false
  //     let videoUri =
  //     "https://d3u57nouj7te3v.cloudfront.net/" +
  //     this.state.VideoSource +
  //     "/" +
  //     this.state.engStepList[urlIndex].stepURl +
  //     ".mp4";
  //   if (this.player) {
  //     this.player.seekTo(0);
  //   } else {
  //     // this.onPressProcedure(this.state.data.procedureList[0],0);
  //   }
   
  //   this.setState({
  //     isLoading: false,
  //     videoUri: videoUri,
  //   });
  //   }
  //   // console.log('testexit')
  // }

  //  --- video voice code ----
    
  };
  onChange = (deviceInfo) => {
    let id=deviceInfo?.browser?.major+
    deviceInfo?.browser?.name+
    deviceInfo?.browser?.version+
    deviceInfo?.cpu?.architecture+
    deviceInfo?.device?.model+
    deviceInfo?.device?.type+
    deviceInfo?.device?.vendor+
    deviceInfo?.engine?.name+
    deviceInfo?.engine?.version+
    deviceInfo?.os?.name+
    deviceInfo?.os?.version+
    deviceInfo?.screen?.defaultFontSize+
    deviceInfo?.screen?.height+
    deviceInfo?.screen?.orientation+
    deviceInfo?.screen?.width+
    deviceInfo?.screen?.widthEm+
    deviceInfo?.ua
    // console.log('Screenheight', id)
    let id1=id.replace(/\s/g, "")
    let id2=id1.replace(/\\|\//g,'')
    this.setState({uuid:id2})
  }
  render() {
    // {console.log('sdugvbjsdvb',translations[this.state.languageCode])}
    // {console.log('hvjdvh2',this.state.languageCode)}
    return (
      <div>
        {this.state.windowWidth>1023?
      <div className="container body-content ">
        <Device onChange={(e) => {this.onChange(e)}} />
        {this.state.isInvalid ? (
          <div className="grid-design">
            {translations[this.state.languageCode].Invalidurlpleasetrywithsomevalidurl}
          </div>
        ) :
        this.state.isLoading ? (
          <div className="grid-design">
            <GridLoader
              sizeUnit={"px"}
              size={10}
              margin={"2px"}
              color={"#5a7ced"}
              loading={this.state.isLoading}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className="form-group row m-0 logo-section">
              <div className="col-sm-3">
                <img src={dellLogo1} />
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-8">
                <img src={dellLogo2} />
              </div>
           
              
              
            </div>
           

            
            <form method="post">
              <div className="form-horizontal">
                <div className="form-group m-0 img">
                  <div className="row">
                    <div className="col-sm-1 col-md-1 img-section">
                      <img src={this.state.ProductImgName} />
                    </div>
                    <div className="col-sm-6 col-md-6 text-section">
                      <div className="large">{this.state.modelName}</div>
                      <div className="small">
                        {this.state.CategoryName=='G Series'?"G ":this.state.CategoryName} {translations[this.state.languageCode].series}
                      </div>
                    </div>
                    {this.state.isVisible?
              <div className="col-sm-5 col-md-5 selectBoxHeader">
              <span className="text-black fs-18"> {this.state.dropdownName}</span>
                <select 
                onChange={(e) => {
                  let modelValue = e.target.value;
                 this.setState({isDropdownLoading:true})
                  this.fetchData(modelValue, this.state.path_array1, this.state.item);
                    
                  }} className="selectBoxPro">
                {this.state.dropdownData.map((item, index) => (
                  
                  <option value={item.subCatModel}>{item.subCatName}</option>
                 
                ))}
                </select>
            </div> :null}
                  </div>
                </div>
                <div className="row">
                  <br />
                </div>
                <div></div>
                <React.Fragment>
                {this.state.isDropdownLoading ? (
          <div className="grid-design">
            <GridLoader
              sizeUnit={"px"}
              size={10}
              margin={"2px"}
              color={"#5a7ced"}
              loading={this.state.isDropdownLoading}
            />
          </div>
        ) :
                <div className="form-group m-0 left-section row">
                  <div className="col-md-3 bor-1">
                    <div>
                      <div className="text-left">
                        <div className="txt">
                        {translations[this.state.languageCode].Partreplacement} <br /> {translations[this.state.languageCode].videos}
                        </div>
                      </div>
                      <div className="sml-txt">
                      {translations[this.state.languageCode].Selectaparttopreviewthepartreplacementprocedure}
                        
                      </div>
                      <div>
                        {this.state.data.procedureList.map((item, index) => (
                          <div key={index} className="menu-parent active">
                            <div
                              onClick={() => {
                                this.onPressProcedure(item, index);
                                const analytics = getAnalytics();
       logEvent(analytics, 'Procedure',{uniqueId:this.state.uniqueId,procedure_name:item.procedureName,product_name: this.state.path_array1[0],
       product_language: this.state.path_array1[1]});
                                 }}
                              className={
                                this.state.selectedItem == index
                                  ? "menu-title active"
                                  : "menu-title"
                              }
                            >
                              {item.procedureName}
                            </div>
                            {index == this.state.selectedItem ? (
                              <div id="pro_0" className="menu">
                                <div
                                  onClick={() => {
                                    this.onSequenceChange(true);
                                  }}
                                  id="remove_0"
                                  className={
                                    this.state.isRemove
                                      ? "submenu-title active"
                                      : "submenu-title"
                                  }
                                >
                                  {translations[this.state.languageCode].Remove}
                                </div>
                                <div
                                  onClick={() => {
                                    this.onSequenceChange(false);
                                  }}
                                  id="install_0"
                                  className={
                                    !this.state.isRemove
                                      ? "submenu-title active"
                                      : "submenu-title"
                                  }
                                >
                                  {translations[this.state.languageCode].Install}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="video-menu col-sm-12 col-md-9">
                    <div className="row col-sm-12 col-md-12 p-15">
                      <div className="col-sm-12 col-md-9 text-center p-15">
                        <div id="videopanel" className="">
                          
                          <ReactPlayer
                            ref={(player) => (this.player = player)}
                            url={this.state.videoUri}
                            playing={this.state.isPlaying}
                            controls={true}
                            width={585}
                            volume={10}
                            height={328}
                            config={config}
                            muted={this.state.isMute}
                            onEnded={() => {
                              isVideoChange=true
                              // console.log('test22',isVideoChange)
                              this.onVideoChange();
                            }}
                            onPause={() => {
                              // console.log('onPause')
                              // console.log('after:',isReady,this.state.videoUri)
                              // isReady=true
                              //   window.speechSynthesis.cancel();
                              //   this.setState({
                              //     setSpeaking:false
                              //   })
                              // setTimeout(() => {
                              //   isReady=true
                              //   window.speechSynthesis.cancel();
                              //   this.setState({
                              //     setSpeaking:false
                              //   })
                                
                              // }, 1000);
                                
                              
                              
                              
                            }}
                            
                            
                            onProgress={() => {
                              if(isVideoChangeCheck){
                                this.handleSpeak(this.state.stepListarray[urlIndex].stepInforTxt)
                                isVideoChangeCheck=false
                              }
                              
                              
                            }}
                            
                          />
                         
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 p-15">
                        <div id="seqdata" className="seqdata">
                         
                          {this.state.isRemove ? (
                            <div>
                              {this.state.sequnenceList.map((item, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    this.onPressSequence(item, index);
                                  }}
                                  
                                  id="seqmenu_0"
                                  className={
                                    this.state.selectedSequence == index
                                      ? "submenu-text active"
                                      : "submenu-text"
                                  }
                                >
                                  <div className="color-3">
                                    {" "}
                                    {index + 1}/
                                    {this.state.sequnenceList.length}
                                  </div>
                                  <div className="seq-name">
                                    {" "}
                                    {item.sequenecName}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              {this.state.sequnenceList.map((item, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      this.onPressSequence(item, index);
                                    }}
                                    id="seqmenu_0"
                                    className={
                                      this.state.selectedSequence == index
                                        ? "submenu-text active"
                                        : "submenu-text"
                                    }
                                  >
                                     <div className="color-3">
                                      {" "}
                                      {index + 1}/
                                      {this.state.sequnenceList.length}
                                    </div>
                                    <div className="seq-name">
                                      {" "}
                                      {item.sequenecName}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12 c-section p-15">
                      <div className="col-sm-12 col-md-9">
                        <div id="pro_name">{this.state.stepName}</div>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div
                            onClick={() => {
                              this.onSequenceChange(true);
                            }}
                            className={
                              this.state.isRemove
                                ? "pull-left col-md-6 tab active"
                                : "pull-left col-md-6 tab"
                            }
                          >
                            {this.state.isRemove ? (
                              <img src={logo3} className="img-remove-on" />
                            ) : (
                              <img
                                src={logo2}
                                className="img-remove-off hide"
                              />
                            )}
                            <div className="remove"> 
                            {translations[this.state.languageCode].Remove}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              this.onSequenceChange(false);
                            }}
                            className={
                              this.state.isRemove
                                ? "pull-right col-md-6 text-right tab"
                                : "pull-right col-md-6 text-right tab active"
                            }
                          >
                            <div className="install"> 
                            {translations[this.state.languageCode].Install} 
                            </div>
                            {this.state.isRemove ? (
                              <img src={logo4} className="img-install-off" />
                            ) : (
                              <img
                                src={logo5}
                                className="img-install-on hide"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="stepdata">
                      <div>
                        <div className="col-sm-12 col-md-12 p-15">
                          <table>
                            <tbody>
                              {this.state.stepListarray.map((item, index) => (
                                <tr
                                  key={index}
                                  id="step_play_0"
                                  className="bor-grey"
                                >
                                  <td className="tleft">
                                    {item.stepName == "Lock" ? (
                                      <img src={logo6} className="step-icon" />
                                    ) : item.stepName == "Screw" ? (
                                      <img src={logo7} className="step-icon" />
                                    ) : item.stepName == "Part" ? (
                                      <img src={logo8} className="step-icon" />
                                    ) : item.stepName == "Tape" ? (
                                      <img src={logo9} className="step-icon" />
                                    ) : item.stepName == "Cable" ? (
                                      <img src={logo10} className="step-icon" />
                                    ) : item.stepName == "Latch" ? (
                                      <img src={logo11} className="step-icon" />
                                    ) : (
                                      <img src={logo12} className="step-icon" />
                                    )}
                                  </td>
                                  <td className="tcenter">
                                    <div className="tstep">
                                    {translations[this.state.languageCode].Step} {item.stepCount}
                                    </div>
                                    <b> {item.stepTxt}</b>
                                  </td>
                                  <td className="tright">
                                    <b> {item.stepInforTxt}</b>
                                  </td>
                                </tr>
                              ))}

                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                </React.Fragment>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>:
      <React.Fragment>
      <div className="appMainContainer">
        <Device onChange={(e) => {this.onChange(e)}} />
        {this.state.isInvalid ? (
          <div className="grid-design">
            {translations[this.state.languageCode].Invalidurlpleasetrywithsomevalidurl}
          </div>
        ) :
        this.state.isLoading ? (
          <div className="grid-design">
            <GridLoader
              sizeUnit={"px"}
              size={10}
              margin={"2px"}
              color={"#5a7ced"}
              loading={this.state.isLoading}
            />
          </div>
        ) : (
      <div className="contentArea">
        <img src={systerListHeader2} className="topImg1" />
        <div className="selectBoxArea1">
          <img
           onClick={() => {
            sessionStorage.clear();
            const { navigate } = this.props;
            navigate(`/`);
          }} src={homeIconNew} className="menuIconNew" />
          <img src={menuIconNew} onClick={()=>
          this.setState({ismenu:!this.state.ismenu})
         }className="homeIconNew" />
          <div className="LanguageTxt dispalyNone">Language</div>
          <select className="selectBoxlang dispalyNone">
            <option value="en">English</option>
            <option value="ccc">中文</option>
            <option value="es">Español</option>
            <option value="ja">日本語</option>
            <option value="pt">Português</option>
          </select>
        </div>
       
        <div className={!this.state.ismenu?"selectBoxArea2 dispalyNone":"selectBoxArea2"}>
          <div className="SelectSystemTxt">Choose a part</div>
          <select className="SelectSystem"
           onChange={(e) => {
            this.state.data.procedureList.map((item, index) => {
              if(e.target.value==item.procedureName){
                this.onPressProcedure(item, index);
            const analytics = getAnalytics();
            logEvent(analytics, 'Procedure',{uniqueId:this.state.uniqueId,procedure_name:item.procedureName,product_name: this.state.path_array1[0],
            product_language: this.state.path_array1[1]});
              }
            })
            
             }}>
            <option value="Select">Select</option>
            {this.state.data.procedureList.map((item, index) => (
              <option value={item.procedureName}>{item.procedureName}</option>
            ))}
          </select>
      
          <div
          
          className="ChoosemodelTxt">{translations[this.state.languageCode].Remove}/ {translations[this.state.languageCode].Install}</div>
          <select onChange={(e) => {
            if(e.target.value=="Remove"){
              // console.log('dfhbdjfhdjfh')
              this.onSequenceChange(true);
            }else{
              // console.log('dfhbdjfhdjfh1')
              this.onSequenceChange(false);
            }
          }} className="Choosemodel">
            <option value="Select">Select</option>
            <option value="Remove">{translations[this.state.languageCode].Remove}</option>
            <option value="Install">{translations[this.state.languageCode].Install}</option>
          </select>
        </div>
        {/* <div className="col-sm-1 col-md-1 img-section">
                      <img src={this.state.ProductImgName} />
                    </div>
                    <div className="col-sm-6 col-md-6 text-section">
                      <div className="large">{this.state.modelName}</div>
                      <div className="small">
                        {this.state.CategoryName} {translations[this.state.languageCode].series}
                      </div>
                    </div> */}
        <div className={!this.state.ismenu?" contentAreaPhone topMenuHidden":"contentAreaPhone"}>
          <div className="partnameArea">
            <img src={this.state.ProductImgName} className="partnameImg" />
            <div className="partnameTxt1">{this.state.modelName}</div>
            <div className="partnameTxt2">{this.state.CategoryName} {translations[this.state.languageCode].series}</div>
          </div>
          <div className="videodiv">
            <div className="videoArea" >
            <ReactPlayer
                            ref={(player) => (this.player = player)}
                            url={this.state.videoUri}
                            playing={this.state.isPlaying}
                            controls={true}
                            
                            volume={10}
                           
                            config={config}
                            muted={this.state.isMute}
                            onPause={() => {
                              console.log('test1')
                              // window.speechSynthesis.cancel();
                              // this.setState({
                              //   setSpeaking:false
                              // })
                            }}
                            onEnded={() => {
                              isVideoChange=true
                              // console.log('test22',isVideoChange)
                              this.onVideoChange();
                            }}
                          />
            </div>
            <div className="coverArea">
             
              {/* {console.log('hvbhjvb',this.state.sequnenceList)} */}
              {this.state.isRemove ? (
                            <div>
                              {this.state.sequnenceList.map((item, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    // console.log('hvbhbvs')
                                    this.onPressSequence(item, index);
                                  }}
                                  
                                  id="seqmenu_0"
                                  className={
                                    this.state.selectedSequence == index
                                      ? "submenu-text active"
                                      : "submenu-text"
                                  }
                                >
                                  <div
                                  
                                  onClick={() => {
                                    // console.log('hvbhbvs')
                                    this.onPressSequence(item, index);
                                  }}className="color-3">
                                    {" "}
                                    {index + 1}/
                                    {this.state.sequnenceList.length}
                                  </div>
                                  <div
                                  
                                  onClick={() => {
                                    // console.log('hvbhbvs')
                                    this.onPressSequence(item, index);
                                  }}className="seq-name">
                                    {" "}
                                    {item.sequenecName}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              {this.state.sequnenceList.map((item, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      // console.log('hvbhbvs1')
                                      this.onPressSequence(item, index);
                                    }}
                                    id="seqmenu_0"
                                    className={
                                      this.state.selectedSequence == index
                                        ? "submenu-text active"
                                        : "submenu-text"
                                    }
                                  >
                                     <div
                                     
                                     onClick={() => {
                                      // console.log('hvbhbvs')
                                      this.onPressSequence(item, index);
                                    }}className="color-3">
                                      {" "}
                                      {index + 1}/
                                      {this.state.sequnenceList.length}
                                    </div>
                                    <div
                                    onClick={() => {
                                      // console.log('hvbhbvs')
                                      this.onPressSequence(item, index);
                                    }} className="seq-name">
                                      {" "}
                                      {item.sequenecName}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
              
              
            </div>
          </div>
          <div className="productname">
            {this.state.stepName}
          </div>
          <div className="tableArea" id="stepdata">
          {/* {console.log('hvbhjvb1',this.state.stepListarray)} */}
          <table>
                            <tbody>
                              {this.state.stepListarray.map((item, index) => (
                                <tr
                                  key={index}
                                  id="step_play_0"
                                  className="bor-grey"
                                >
                                  <td className="tleft">
                                    {item.stepName == "Lock" ? (
                                      <img src={logo6} className="step-icon" />
                                    ) : item.stepName == "Screw" ? (
                                      <img src={logo7} className="step-icon" />
                                    ) : item.stepName == "Part" ? (
                                      <img src={logo8} className="step-icon" />
                                    ) : item.stepName == "Tape" ? (
                                      <img src={logo9} className="step-icon" />
                                    ) : item.stepName == "Cable" ? (
                                      <img src={logo10} className="step-icon" />
                                    ) : item.stepName == "Latch" ? (
                                      <img src={logo11} className="step-icon" />
                                    ) : (
                                      <img src={logo12} className="step-icon" />
                                    )}
                                  </td>
                                  <td className="tcenter tcenterFont">
                                    <div className="tstep">
                                    {translations[this.state.languageCode].Step} {item.stepCount}
                                    </div>
                                    <b> {item.stepTxt}</b>
                                  </td>
                                  <td className="tright trightFont">
                                    <b> {item.stepInforTxt}</b>
                                  </td>
                                </tr>
                              ))}

                              
                            </tbody>
                          </table>
          </div>
        </div>
      </div >)}
      </div ></React.Fragment>
      }
      </div>
    );
  }
}

export default withNavigate (App);
